import React, { useEffect, useState } from "react"
import { Container, Row, Col } from "react-grid-system"
import { graphql } from "gatsby"
import styled from "styled-components"
import Masonry from "react-masonry-css"
import "src/components/core/layout/masonry.css"
import Layout from "src/components/core/layout/layout"
import PhotoTile from "src/components/photos/photo-tile"
import "src/components/photos/lightbox.scss"
import SEO from 'src/components/seo/SEO';

export const query = graphql`
  query ModelsQuery {
    allPrismicModel {
      nodes {
        data {
          brand {
            text
          }
          name {
            text
          }
          photo {
            fluid {
              aspectRatio
              base64
              sizes
              src
              srcSet
              srcSetWebp
              srcWebp
            }
          }
        }
      }
    }
  }
`

export default ({ data }) => {
  const breakpointColumnsObj = {
    default: 4,
    1100: 3,
    700: 3,
    500: 2,
  }

  const photos = []
  data.allPrismicModel.nodes.forEach(model =>
    photos.push(
      <PhotoTile
        key={model.data.photo ? model.data.photo.md : null}
        photo={model.data.photo.md ? model.data.photo.md.url : null}
        name={model.data.name ? model.data.name.text : null}
        project={model.data.brand ? model.data.brand.text : null}
        link="modal"
      />
    )
  )
  return (
      <>
      <SEO  title="Models"/>
    <Layout>
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {photos}
      </Masonry>
    </Layout>
    </>
  )
}
